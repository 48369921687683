'use client'
import { useState, useContext, createContext } from "react"

/**
 * Create a global context for session data
 */
export type ISessionContext = [string | undefined, (email: string) => void]
const sessionContext = createContext<ISessionContext | null>(null)

export function useSessionContext() {
  return useContext(sessionContext)
}

export const SessionContext = ({ children }: any) => {
  const [sessionEmail, setSessionEmail] = useState<string>()
  return (
    <sessionContext.Provider value={[sessionEmail, setSessionEmail]}>
      {children}
    </sessionContext.Provider>
  )
}