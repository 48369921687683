'use client'

// React packages
import { ReactNode } from "react"
import { useEffect } from 'react'

// Next packages
import Head from 'next/head'
import Script from 'next/script'

// Internal Packages
import { useCookie } from '@/utils/Session'
import { init } from '@/utils/Note'

// External Packages
import { v4 } from 'uuid'

// Components
import { Footer } from "@/layout/Footer"

// Styles
import '../styles/global.css'
import { Inter } from 'next/font/google'
import { SessionContext } from "./_components/context"
const inter = Inter({
  subsets: ['latin'],
})

/**
 * The root layout
 * @param param0 
 * @returns 
 */
export default function RootLayout({
  children,
}: Readonly<{
  children: ReactNode
}>) {
  const [userGuid, setUserGuid] = useCookie('userGuid')

  // Create an arbitrary user guid so that I can determine 
  // if it's me or someone else when viewing the analytics
  useEffect(() => {
    const g = userGuid()
    if (!g) {
      setUserGuid(v4().toString())
    }

    init()
  }, [])

  return (
    <html lang="en">
    <SessionContext>
      <Head>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-6FLYFP8YTD"></script>
      </Head>
      <Script id="ms clarity">
        {process.env.NEXT_PUBLIC_IS_PREVIEW !== 'true' && `
        (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "i812wqwb22");
        `}
      </Script>
      <Script id="ga4"> 
      { 
        // process.env.VERCEL_ENV === 'production' && 
      `
        <!-- Google tag (gtag.js) -->
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-6FLYFP8YTD');
      `}
      </Script>
      <Script id="meta-pixel">
        {process.env.NEXT_PUBLIC_IS_PREVIEW !== 'true' && `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '2193397757693794');
          fbq('track', 'PageView');
        `}
      </Script>

      <body className={`${inter.className} bg-theme-gray-50 text-theme-gray-800`}>
        {children}
      </body>
    </SessionContext>
    </html>
  );
}