/**
 * LOCALHOST NOTE - cookies are not set on localhost, use 127.0.0.1 instead
 */
const domain = (hostname:string) => {
  return hostname === 'localhost' ? 'localhost' : '.dessertdata.com'
}

export const setCookie = (key: string) => (value: string) => {
  // Note this is how to write multiple cookies
  // https://stackoverflow.com/questions/16842226/setting-multiple-cookies-in-javascript
  document.cookie = `${key}=${value};max-age=604800;path=/;domain=${domain(window.location.hostname)};SameSite=Strict;`;
};

export const getCookie = (key: string) => () => {
  // console.log('getCookie', document.cookie)
  const cookies = document.cookie.split(/; ?/);
  let cookie = null
  
  for (let c of cookies) {
    if (c.startsWith(`${key}=`)) {
      cookie = c
    }
  }

  if (cookie) {
    const parsed = cookie.split('=')[1] as string

    if (parsed === 'true') {
      return true
    } else if (parsed === 'false') {
      return false
    } else {
      return cookie.split('=')[1] as string;
    }
  }

  return null;
};

export const deleteCookie = (key: string) => () => {
  // https://stackoverflow.com/a/2138471
  document.cookie = `${key}=;path=/;domain=${domain(window.location.hostname)};max-age=-99999999;`
}

export function useCookie(cookieKey: string): [Function, Function, Function] {
  if (typeof window !== 'undefined') {
    return [getCookie(cookieKey), setCookie(cookieKey), deleteCookie(cookieKey)]
  }

  const nullFunc = () => null
  return [nullFunc, nullFunc, nullFunc]
}
